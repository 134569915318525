var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agreement-box", attrs: { id: "agreementBox" } },
    [
      _c("i-header", {
        attrs: {
          title: _vm.config["title"] ? _vm.config["title"].template : "",
        },
        on: { "back-event": _vm.toBack },
      }),
      _c(
        "div",
        { staticClass: "cnt-box" },
        [
          _c("div", {
            staticClass: "cnt",
            staticStyle: { width: "100%" },
            attrs: { id: "element-to-print" },
            domProps: { innerHTML: _vm._s(_vm.renderCntTemplate) },
            on: {
              click: function ($event) {
                return _vm.handlePreview($event)
              },
            },
          }),
          _vm.disclaimerType == 2
            ? _c(
                "div",
                { staticClass: "check-box" },
                [
                  _c(
                    "i-checkbox",
                    {
                      attrs: { "checked-color": "var(--themeColor)" },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("在此勾选，确认您已充分阅读、理解并同意上述内容。")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.disclaimerType == 2
            ? _c(
                "i-button",
                {
                  staticClass: "btn-box",
                  attrs: {
                    disabled: !_vm.checked || _vm.disabled,
                    round: "",
                    loading: _vm.loading,
                    "loading-text": "同意",
                    color: "var(--themeColor)",
                    block: "",
                  },
                  on: { click: _vm.toAgree },
                },
                [_vm._v("同意")]
              )
            : _c(
                "i-button",
                {
                  staticClass: "btn-box",
                  attrs: {
                    disabled: _vm.disabled,
                    round: "",
                    loading: _vm.loading,
                    "loading-text": _vm.signImgUrl ? "同意" : "签名",
                    color: "var(--themeColor)",
                    block: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.signImgUrl
                        ? _vm.toUploadFile
                        : (_vm.showESignBox = true)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.signImgUrl ? "同意" : "签名"))]
              ),
        ],
        1
      ),
      _c(
        "i-popup",
        {
          staticStyle: { width: "100vw", height: "100vh" },
          attrs: { "get-container": "#agreementBox" },
          model: {
            value: _vm.showESignBox,
            callback: function ($$v) {
              _vm.showESignBox = $$v
            },
            expression: "showESignBox",
          },
        },
        [
          _c("eSign", {
            attrs: {
              mappingData:
                _vm.disclaimerType == 5 ||
                (_vm.joinStatus != 1 &&
                  _vm.disclaimerType == 4 &&
                  _vm.isDuringMice)
                  ? _vm.signMappingData
                  : null,
            },
            on: {
              handleClose: function ($event) {
                _vm.showESignBox = false
              },
              handleSignUrl: _vm.handleSignUrl,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }