<template>
    <div class="agreement-box" id="agreementBox">
        <i-header :title="config['title'] ? config['title'].template : ''" @back-event="toBack"></i-header>
        <div class="cnt-box">
            <div id="element-to-print" style="width: 100%" class="cnt" v-html="renderCntTemplate" @click="handlePreview($event)"></div>
            <div class="check-box" v-if="disclaimerType == 2">
                <i-checkbox v-model="checked" checked-color="var(--themeColor)">在此勾选，确认您已充分阅读、理解并同意上述内容。</i-checkbox>
            </div>
            <i-button v-if="disclaimerType == 2" :disabled="!checked || disabled" round
                      :loading="loading" @click="toAgree" class="btn-box"
                      loading-text="同意"
                      color="var(--themeColor)" block>同意</i-button>
            <i-button v-else :disabled="disabled" round class="btn-box"
                      :loading="loading" @click="signImgUrl ? toUploadFile : showESignBox = true"
                      :loading-text="signImgUrl ? '同意' : '签名'"
                      color="var(--themeColor)" block>{{ signImgUrl ? '同意' : '签名' }}</i-button>
        </div>
        <i-popup style="width: 100vw;height: 100vh;" v-model="showESignBox" get-container="#agreementBox">
            <eSign :mappingData="disclaimerType == 5 || (joinStatus != 1 && disclaimerType == 4 && isDuringMice) ? signMappingData : null"
                   @handleClose="showESignBox = false" @handleSignUrl="handleSignUrl"/>
        </i-popup>
    </div>
</template>

<script>
import generic from "../../utils/generic";
import eSign from "@/components/eSign"
import UploadPlug from "@/components/iFinderFrame/controllers/uploadPlug";
import moment from "moment";
import html2pdf from "html2pdf.js"
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";

export default {
    name: "Agreement",
    components: {
        eSign
    },
    data() {
        return {
            checked: false,
            loading: false,
            cnt: '',
            disclaimerId: '',
            speakerId: this.$smartStorage.get('speakerId') || '',
            disabled: false,
            config: {},
            showESignBox: false,
            signImgUrl: '',
            disclaimerType: this.$smartStorage.get('disclaimerType') || 2,
            mappingData: {
                "catalog": this.$smartStorage.get('catalog') || '讲者协议函',
                "processId": this.$smartStorage.get('miceId'),
                relatedId: this.$smartStorage.get('speakerId')
            },
            signMappingData: {
                "catalog": this.$smartStorage.get('signCatalog') || '讲者签到记录',
                "processId": this.$smartStorage.get('miceId'),
                relatedId: this.$smartStorage.get('speakerId')
            },
            signPdfUrl: '',

            uploadInstance: null,
            currentDataTime: String(moment(new Date()).format('YYYY/MM/DD HH:mm')),
            agreementData: this.$smartStorage.get('agreementData') || {},   // 模版所需信息
            isDuringMice: false,   // 是否是会议期间
            joinStatus: this.$smartStorage.get('joinStatus') || 0  // 是否需要签到
        }
    },
    computed: {
        renderCntTemplate() {
            return generic.templateRender(this.cnt,Object.assign(
                    {
                        currentData: moment(new Date()).format('YYYY[年]MM[月]DD[日]'),
                        signImgUrl: this.signImgUrl
                    },
                    this.agreementData))
        }
    },
    created() {
        this.getConfig()
        let timeStart = new Date(this.$smartStorage.get('timeStart')).getTime()
        let timeEnd = new Date(this.$smartStorage.get('timeEnd')).getTime()
        let currentTime = new Date().getTime()
        // 判断是否在会议期间
        if(timeStart <= currentTime && timeEnd >= currentTime) {
            this.isDuringMice = true
        }
    },
    mounted() {
        this.uploadInstance = new UploadPlug({
            host: process.env.VUE_APP_GATEWAY,
            timeout: 50000
        });
    },
    methods: {
        getConfig() {
            generic.getConfigs({
                    group: this.$route.query.type,
                    router: this.$route.path },this.$smartStorage.getAll())
                .then((config) => {
                    this.config = config
                    for (const configKey in config) {
                        if(config[configKey].callback) this[config[configKey].callback]()
                    }
                });
        },
        handlePreview(e) {
            if(e.target.nodeName === 'IMG' && e.target.currentSrc !== '') {
                if (this.viewer) {
                    this.viewer.destroy();
                }

                this.viewer = new Viewer(document.getElementById(e.target.id), {
                    navbar: false,
                    transition: false,
                    zIndex: 3000,
                    toolbar: {
                        zoomIn: true,
                        zoomOut: true,
                        oneToOne: true,
                        reset: true,
                        prev: false,
                        play: false,
                        next: false,
                        rotateLeft: true,
                        rotateRight: true,
                        flipHorizontal: true,
                        flipVertical: true
                    }
                });

                this.viewer.show();
            }
        },
        async init() {
            let _config = generic.replaceEvalObject(this.config['cnt'],this.$smartStorage.getAll())
            let res = await this.$service.GenericPost(_config)
            if(res && res.success && res.content) {
                this.cnt = res.content.content
                this.disclaimerId = res.content.disclaimerId
            }
        },
        toBack() {
            this.$router.back()
        },
        handleSignUrl(url) {
            this.showESignBox = false
            this.signImgUrl = url || ''
            this.loading = true
            this.$nextTick(() => {
                // 讲者签名不需要保存pdf
                if(this.disclaimerType == 5) {
                    this.toAgree()
                    return false
                }
                let printEl = document.getElementById('element-to-print')
                let opt = {
                    margin: 1,
                    filename: '培训协议.pdf',
                    image: {type: 'jpeg', quality: 0.98},
                    html2canvas: {scale: 2,useCORS: true},
                    pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
                    jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait',compress: true},
                    enableLinks: true
                };
                html2pdf().set(opt).from(printEl).output('blob').then((res => {
                    // 上传
                    this.toUploadFile(res,opt.filename)
                })).catch(error => {
                    console.log(error)
                })
            },300)
        },
        convertBase64UrlToBlob(base64, fileName){
            let arr = base64.split(',')
            let bytes = window.atob(arr[1])
            const mimeType = arr[0].match(/:(.*?);/)[1];
            let ab = new ArrayBuffer(bytes.length)
            let ia = new Uint8Array(ab)
            for (let i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i)
            }
            //base64转成Blob
            let _blob = new Blob([ab], { type: mimeType })
            return new File([_blob], fileName, {type: mimeType})
        },
        async toUploadFile(fileData,fileName) {
            let fileObj = new File([fileData], fileName, {type: fileData.type})
            let response = await this.uploadInstance.uploadAsync({
                fileItem: fileObj,
                ossKey: '',
                bucketAlias: 'SmartEvent',
                geoLocation: null,
                index: 0,
                mappingData: this.mappingData,
                isMapping: true,
                progressCallback: () => {}
            });
            if(response.status && response.content) {
                this.signPdfUrl = response.content.url
                // this.$publicFun._browser(response.content.url)
                this.toAgree()
            } else {
                if(response.code == 66666) {
                    this.$idialog.confirm({
                        title: '提示',
                        confirmButtonText: "重新上传",
                        message: "由于网络原因导致签署失败，请点击下面的重新上传按钮，系统会再次尝试上传签署信息，等待期间请不要关闭此界面。",
                    }).then(async () => {
                        this.toAgree()
                    }).catch(() => {
                        return false
                    });
                }
            }
            this.loading = false
        },
        toAgree() {
            // pdf上传失败的情况
            if(this.disclaimerType == 4 && !this.signPdfUrl) {
                this.handleSignUrl(this.signImgUrl)
                return false
            }
            let _config = generic.replaceEvalObject(this.config['submit'],this.$data)
            this.loading = true
            this.$service.GenericPost(_config).then((res) => {
                if(res && res.success) {
                    this.disabled = true
                    this.$itoast(this.disclaimerType == 2 ? '操作成功' : '签署成功')
                    if(_config.afterAction) this[_config.afterAction]()
                }
            })
            this.loading = false
        },
        toHomePage() {
            this.$router.push({
                path: '/'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.agreement-box {
    width: 100vw;
    height: 100%;
    background-color: #FFFFFF;
    .cnt-box {
        height: calc(100% - 0.44rem);
        overflow-y: scroll;
        padding: 0 0.18rem 0.3rem 0.18rem;
        box-sizing: border-box;
        .sign-box {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
                width: 1rem;
                height: 0.5rem;
                display: block;
            }
        }
        /deep/ img[src=""],img:not([src]){
            opacity:0;
        }
        .check-box {
            margin-top: 0.27rem;
        }
        .btn-box {
            margin-top: 0.15rem;
        }
    }
}
</style>
