<template>
    <div class="eSign-container">
        <i-header v-if="showCanvas" title="电子签名" @back-event="toBack"></i-header>
        <div class="canvas-box">
            <vue-esign ref="esign" id="canvas"
                       v-if="showCanvas"
                       :width="isVerticalScreen ? canvasWidth : canvasHeight"
                       :height="isVerticalScreen ? canvasHeight : canvasWidth"
                       :isCrop="isCrop" :lineWidth="lineWidth"
                       :lineColor="lineColor"
                       :bgColor.sync="bgColor"/>
            <div class="tips" v-show="showTips">{{ errorTips }}</div>
        </div>
        <div class="btn-box" v-if="showCanvas">
            <div class="btn-item del" @click="handleReset">清空</div>
            <i-button round class="btn-item normal"
                      :loading="confirmLoading" @click="handleGenerate"
                      loading-text="保存"
                      color="var(--themeColor)" block>保存</i-button>
        </div>
    </div>
</template>

<script>
import UploadPlug from "@/components/iFinderFrame/controllers/uploadPlug";

export default {
    name: "index",
    props: {
        // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
        isCrop: {
            type: Boolean,
            default: () => {
                return false
            }
        },
        // 画笔粗细
        lineWidth: {
            type: Number,
            default: () => {
                return 2
            }
        },
        // 画笔颜色
        lineColor: {
            type: String,
            default: () => {
                return '#000000'
            }
        },
        // 画布背景色，为空时画布背景透明，支持多种格式 '#ccc'，'#E5A1A1'，'rgb(229, 161, 161)'，'rgba(0,0,0,.6)'，'red'
        bgColor: {
            type: String,
            default: () => {
                return '#ffffff'
            }
        },
        // 水印
        watermark: {
            type: String,
            default: () => {
                return ''
            }
        },
        mappingData: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    data () {
        return {
            resultImg: '',
            showTips: false,
            showCanvas: false,
            canvasWidth: '',
            canvasHeight: '',
            isVerticalScreen: true,  // 是否竖屏
            confirmLoading: false,
            errorTips: '*请完成签名！！！'
        }
    },
    mounted () {
        this.uploadInstance = new UploadPlug({
            host: process.env.VUE_APP_GATEWAY
        });
        setTimeout(() => {
            let canvasBoxElm = document.querySelector('.canvas-box')
            let elmWidthStr = window.getComputedStyle(canvasBoxElm).getPropertyValue('width')
            let elmHeightStr = window.getComputedStyle(canvasBoxElm).getPropertyValue('height')
            this.canvasWidth = Number(elmWidthStr.substr(0,elmWidthStr.length - 2))
            this.canvasHeight = Number(elmHeightStr.substr(0,elmHeightStr.length - 2))
            document.body.style.setProperty('--canvasHeight', this.canvasHeight + 'px')
            this.showCanvas = true
        }, 300)
        window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.orientChange, false);
    },
    watch: {
        isVerticalScreen(newVal) {
            this.showCanvas = false
            setTimeout(() => {
                this.showCanvas = true
            })
        }
    },
    beforeDestroy() {
        window.removeEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.orientChange);
    },
    methods: {
        orientChange() {
            if (window.orientation === 180 || window.orientation === 0) {
                this.isVerticalScreen = true
            }
            if (window.orientation === 90 || window.orientation === -90 ){
                this.isVerticalScreen = false
            }
        },
        toBack() {
            this.showTips = false
            this.handleReset()
            this.$emit('handleClose')
        },
        handleReset () {
            this.$refs.esign.reset()
        },
        // 生成签名的base64图片
        handleGenerate () {
            this.confirmLoading = true
            this.$refs.esign.generate().then(async (res) => {
                this.showTips = false
                this.resultImg = res;
                let _width = this.isVerticalScreen ? this.canvasWidth : this.canvasHeight
                let _height = this.isVerticalScreen ? this.canvasHeight : this.canvasWidth
                let _deg = this.isVerticalScreen ? -90 : 0
                await this.rotateBase64Img(res,_width,_height,_deg).then((imgUrl) => {
                    this.resultImg = imgUrl
                })
                if(this.mappingData) {
                    await this.toUploadFile(this.resultImg)
                } else {
                    await this.$emit('handleSignUrl',this.resultImg)
                }
                this.confirmLoading = false
            }).catch(err => {
                // 画布没有签字
                this.showTips = true
                this.confirmLoading = false
            })
        },
        // 旋转图片
        rotateBase64Img(imgUrl, imgW, imgH, deg) {
            return new Promise((resolve,reject) => {
                let Img = new Image(),
                        dataURL = ''
                Img.src = imgUrl;
                //要先确保图片完整获取到，这是个异步事件
                Img.onload = () => {
                    let canvas = document.createElement("canvas"), //创建canvas元素
                            ctx = canvas.getContext("2d"),
                            //确保canvas的尺寸和图片一样
                            width = imgW,
                            height = imgH,
                            w = deg % 180 == 0 ? width : height,
                            h = deg % 180 == 0 ? height : width,
                            obj = {
                                x: w / 2,
                                y: h / 2,
                                w: width,
                                h: height
                            };
                    canvas.width = w;
                    canvas.height = h;
                    if(this.watermark) {
                        // 添加日期水印
                        ctx.font = '16px Arial'
                        ctx.textAlign = 'right'
                        // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
                        ctx.fillText(this.watermark, w - 20, h - 20)
                    }

                    ctx.translate(obj.x, obj.y);
                    ctx.rotate(deg * Math.PI / 180);
                    ctx.translate(-obj.x, -obj.y);

                    ctx.drawImage(Img, obj.x - obj.w / 2, obj.y - obj.h / 2, obj.w, obj.h); //将图片绘制到canvas中
                    dataURL = canvas.toDataURL(); //转换图片为dataURL（转换成base64）
                    resolve(dataURL)
                };
            })
        },
        // base64转化成图片
        base64ImgToFile(dataUrl, fileName='file') {
            const arr = dataUrl.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const suffix = mime.split('/')[1]
            const bStr = atob(arr[1]);
            let n = bStr.length;
            var u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bStr.charCodeAt(n);
            }
            return new File([u8arr], `${fileName}.${suffix}`, { type: mime });
        },
        async toUploadFile(fileData) {
            let fileObj = this.base64ImgToFile(fileData,this.mappingData.catalog)
            let response = await this.uploadInstance.uploadAsync({
                fileItem: fileObj,
                ossKey: '',
                bucketAlias: 'SmartEvent',
                geoLocation: null,
                index: 0,
                mappingData: this.mappingData,
                isMapping: true,
                progressCallback: () => {}
            });
            if(response.status && response.content) {
                this.resultImg = response.content?.url
                this.$emit('handleSignUrl',response.content?.url)
            } else {
                this.errorTips = '由于网络原因导致签到失败，请点击保存按钮重试！'
                this.showTips = true
            }
        }
    }
}
</script>

<style lang="less" scoped>
@media screen and  (orientation:  portrait){
    .i-header {
        transform: rotate(90deg);
        transform-origin:0% 0%;
        width: var(--canvasHeight);
        position: absolute;
        top: 0;
        left: 100vw;
    }
    .canvas-box {
        width: 70vw !important;
        height: calc(100% - 0.2rem) !important;
        position: absolute;
        right: 0.44rem;
        top: 0.1rem;
        padding: unset !important;
        .tips {
            transform: rotate(90deg);
            transform-origin:0% 0%;
            position: absolute;
            top: 0;
        }
    }
    .btn-box {
        transform: rotate(90deg);
        transform-origin:0% 0%;
        width: var(--canvasHeight);
        position: absolute;
        padding: unset !important;
        left: 15vw;
    }
}
@media screen and (orientation: landscape) {
    /*手机开启横屏*/
    .eSign-container  {
        width: 100vw;
        height: 100%;
    }
}
.eSign-container {
    width: 100vw;
    height: 100%;
    .canvas-box {
        padding: 0 0.1rem;
        box-sizing: border-box;

        canvas {
            border: 1px solid #DEDEDE;
            border-radius: 0.04rem;
        }
        .tips {
            font-size: 0.12rem;
            color: red;
        }
    }
    .btn-box {
        padding: 0 0.1rem;
        box-sizing: border-box;
        margin-top: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btn-item {
            width: fit-content;
            min-width: 0.6rem;
            height: 0.3rem !important;
            border-radius: 0.04rem;
            font-size: 0.14rem;
            padding: 0 0.07rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.1rem;
        }
        .normal {
            background: var(--themeColor);
            box-shadow: 0px 0.02rem 0.08rem 0px var(--gradualColor);
            color: #FFFFFF;
        }
        .del {
            color: #90939A;
            background: rgba(255, 255, 255, 0);
            border: 1px solid #DEDEDE;
            border-radius: 0.04rem;
        }
    }
}
</style>
