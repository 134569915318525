var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eSign-container" },
    [
      _vm.showCanvas
        ? _c("i-header", {
            attrs: { title: "电子签名" },
            on: { "back-event": _vm.toBack },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "canvas-box" },
        [
          _vm.showCanvas
            ? _c("vue-esign", {
                ref: "esign",
                attrs: {
                  id: "canvas",
                  width: _vm.isVerticalScreen
                    ? _vm.canvasWidth
                    : _vm.canvasHeight,
                  height: _vm.isVerticalScreen
                    ? _vm.canvasHeight
                    : _vm.canvasWidth,
                  isCrop: _vm.isCrop,
                  lineWidth: _vm.lineWidth,
                  lineColor: _vm.lineColor,
                  bgColor: _vm.bgColor,
                },
                on: {
                  "update:bgColor": function ($event) {
                    _vm.bgColor = $event
                  },
                  "update:bg-color": function ($event) {
                    _vm.bgColor = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTips,
                  expression: "showTips",
                },
              ],
              staticClass: "tips",
            },
            [_vm._v(_vm._s(_vm.errorTips))]
          ),
        ],
        1
      ),
      _vm.showCanvas
        ? _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "div",
                { staticClass: "btn-item del", on: { click: _vm.handleReset } },
                [_vm._v("清空")]
              ),
              _c(
                "i-button",
                {
                  staticClass: "btn-item normal",
                  attrs: {
                    round: "",
                    loading: _vm.confirmLoading,
                    "loading-text": "保存",
                    color: "var(--themeColor)",
                    block: "",
                  },
                  on: { click: _vm.handleGenerate },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }